import { Layout } from 'react-admin';
import MyAppBar from '../admin/app-bar';
import MyMenu from '../admin/menu';
import React from 'react';


const MyLayout = (props) => <Layout
    {...props}
    appBar={MyAppBar}
    menu={MyMenu}
    
/>;

export default MyLayout;